import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./hoc/ErrorBoundary";
import Routes from "./routes";
import { store } from "./store/store";
import { getTheme } from "./util/localStorage.util";
import { useEffect } from "react";
import { ThemeList } from "./enum/enum";
import "./assets/sass/dark.main.less";

function App() {
  useEffect(() => {
    if (getTheme() && getTheme() === ThemeList.Light) {
      require("./assets/sass/main.less");
    } else {
      require("./assets/sass/dark.main.less");
    }
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
