import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  // FundOutlined,
  TableOutlined
} from "@ant-design/icons";
import { NavLinks } from "../interfaces";

export const sideNavList: NavLinks[] = [
  // { href: "/", icon: <DashboardOutlined />, title: "Dashboard" },
  { href: "/", icon: <TableOutlined />, title: "Orders" },
  { href: "/admin", icon: <UserOutlined />, title: "Admin Management" },
  { href: "/user", icon: <TeamOutlined />, title: "User Management" },
  // { href: "/analytics", icon: <DotChartOutlined />, title: "Analytics" },
  // { href: "/fund", icon: <FundOutlined />, title: "Funds" },
  // {
  //   href: "/report",
  //   icon: <DashboardOutlined />,
  //   title: "Reports",
  //   children: [
  //     { href: "/report/finance", icon: <DotChartOutlined />, title: "Finance" },
  //     { href: "/report/usage", icon: <DotChartOutlined />, title: "Usages" },
  //   ],
  // },
  // { href: "/tables", icon: <TableOutlined />, title: "Tables" },
];
