import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect, Switch } from "react-router";
import { mainRoutes } from "../routes/main.routes";
import { isUserLoggedIn } from "../util/auth.util";
import Header from "./header";
import Navdrawer from "./navdrawer";
import Sidenav from "./sidenav";

const { Content } = Layout;

function MainLayout() {
  const [sidebarColllapse, setSidebarColllapse] = useState<boolean>(false);
  const [colorTheme, setColorTheme] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const hideDrawer = () => {
    setVisible(false);
  };

  const handleSidebarTrigger = () => {
    setSidebarColllapse(!sidebarColllapse);
  };

  const handleThemeChange = () => {
    setColorTheme(!colorTheme);
  };

  useEffect(() => {
    if (colorTheme) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
  }, [colorTheme]);

  if (!isUserLoggedIn()) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Header
        sidebarColllapse={sidebarColllapse}
        changeTheme={handleThemeChange}
        onClose={onClose}
        showDrawer={showDrawer}
      />
      <Sidenav sidebarColllapse={sidebarColllapse} handleSidebarTrigger={handleSidebarTrigger}/>
      <Navdrawer onClose={onClose} visible={visible} hideDrawer={hideDrawer} />
      <Content
        className={`page__wrapper ${sidebarColllapse ? "expanded" : ""}`}
      >
        <Switch>
          {mainRoutes}
          <Redirect to="/pagenotfound" />
        </Switch>
      </Content>
    </>
  );
}

export default MainLayout;
