// import { IAuthReducer } from "./../../core/interface/auth.interface";
import * as adminActions from "../actions/adminActions";

const initState: any = {
  user: {},
};

export const userReducer = (state = initState, action: any): any => {
  switch (action.type) {
    case adminActions.USER_DETAIL: {
      return {
        ...state,
        user: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
