import { ReactNode } from "react";
import { Route } from "react-router";
import { IRouteItem } from "../interfaces";
import { lazy } from "./lazy";

export const authRouteList: IRouteItem[] = [
  {
    name: "Forgot password",
    path: "/forgot-password",
    LazyComponent: lazy(
      () => import("../pages/Forgot-password/Forgotpassword")
    ),
    exact: true,
  },
  {
    name: "Set password",
    path: "/password-setup",
    LazyComponent: lazy(
      () => import("../pages/Pass-setup/PassSetup")
    ),
    exact: true,
  },
  {
    name: "Reset password",
    path: "/reset-password-link",
    LazyComponent: lazy(() => import("../pages/Reset-link/Resetlink")),
    exact: true,
  },
  {
    name: "Resend Link",
    path: "/resend-link",
    LazyComponent: lazy(() => import("../pages/Resend-email/ResendLink")),
    exact: true,
  },
  {
    name: "Confirm email",
    path: "/confirm-email",
    LazyComponent: lazy(() => import("../pages/Confirm-email/ConfirmEmail")),
    exact: true,
  },
  {
    name: "Customer Login",
    path: "/login",
    LazyComponent: lazy(() => import("../pages/Login/Login")),
    exact: true,
  },
  {
    name: "Admin Login",
    path: "/admin/login",
    LazyComponent: lazy(() => import("../pages/Login/AdminLogin")),
    exact: true,
  },
  {
    name: "Signup",
    path: "/signup",
    LazyComponent: lazy(() => import("../pages/Signup/Signup")),
    exact: true,
  },

  {
    name: "Page not found",
    path: "/pagenotfound",
    LazyComponent: lazy(() => import("../pages/Error/Pagenotfound")),
  },
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
  routeList.forEach((route, Key) =>
    routerDom.push(
      <Route
        key={Key}
        {...route}
        children={(props) => <route.LazyComponent {...props} route={route} />}
      />
    )
  );
  return routerDom;
};

export const authRoutes = parseRoutes(authRouteList);
